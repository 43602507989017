<template>
    <div style="height:100vh">

        <div>
            <div style="margin: 7% 33%;padding:10px 5% 150px;background-color: white;border-radius: 25px">
                <div style="width: 80%;margin-left: 10%;float: left;margin-bottom: 5px">
                    <img class="signIn_logo" src="../assets/logo.png"/>
                </div>
                <div style="font-size:1.25rem;font-weight:600; text-align:center;margin-bottom: 40px">
                    Sign in to Contribution-based Software Platform
                </div>


                <Form>
                    <FormItem prop="user">
                        <Input type="text" v-model="loginVO1.email" placeholder="Email">
                            <Icon type="ios-person-outline" slot="prepend"></Icon>
                        </Input>
                    </FormItem>
                    <FormItem prop="password">
                        <Input type="password" v-model="loginVO1.password" placeholder="Password">
                            <Icon type="ios-lock-outline" slot="prepend"></Icon>
                        </Input>
                    </FormItem>
                    <FormItem>
                        <div style="width:60%;float: left">
                            <Input type="text" v-model="captchaValue" placeholder="Captcha">
                                <Icon type="logo-reddit" slot="prepend"></Icon>
                            </Input>
                        </div>
                        <div style="width:40%;float: left">
                            <img style="margin-left:3%;width: 97%;height: 100%" :src="captchaPic" @click="getCaptcha"/>
                        </div>
                    </FormItem>
                    <FormItem>
                        <Button type="primary" style="" @click="submitCheck()">Sign in</Button>
                        <Button type="primary" style="margin-left: 10px" @click="signUp_log()">Register</Button>
                    </FormItem>

                </Form>
                <!--<a @click="signUp_log()">Forgot password?</a>-->
                <!--<strong> OR </strong>-->
                <!--<a @click="signUp_log()">New user? Create an account.</a>-->
            </div>
        </div>
    </div>
</template>
<script>
    import { setUserId } from '@/utils/auth'

    export default {
        name: 'Login',
        data() {
            return {
                loginVO1: {
                    email: '',
                    password: ''
                },
                captchaPic: '',
                captchaKey: '',
                captchaValue: '',
                ruleInline: {
                    user: [
                        {required: true, message: 'Please fill in the user name', trigger: 'blur'}
                    ],
                    password: [
                        {required: true, message: 'Please fill in the password.', trigger: 'blur'},
                        {
                            type: 'string',
                            min: 6,
                            message: 'The password length cannot be less than 6 bits',
                            trigger: 'blur'
                        }
                    ]
                }
            }
        },
        methods: {
            getCaptcha() {
                this.$axios({
                        method: 'get',
                        url: "/captcha/getCaptchaImage?p=" + Math.random(),
                    }
                ).then((response) => {
                    this.captchaPic = "data:image/png;base64," + response.data.data.image;
                    this.captchaKey = response.data.data.key
                })
            },
            submitCheck(){
                var Tself = this;
                if (this.loginVO1.email===""){
                    this.$Message.warning("Empty email!")
                    return;
                }
                if (this.loginVO1.password==="") {
                    this.$Message.warning("Empty password!")
                    return;
                }
                if (this.captchaValue==="") {
                    this.$Message.warning("Empty captcha!")
                    return;
                }
                // 检查验证码
                this.$axios({
                        method: 'post',
                        url: "/captcha/checkCaptcha?code=" + this.captchaValue + "&key=" + this.captchaKey,
                    }
                ).then((response) => {
                    let result = response.data.data.item
                    if (result === "false") {
                        this.$Message.warning("Wrong captcha!")
                        this.getCaptcha()
                    }
                    if(result === "true"){
                        this.handleSubmit()
                    }
                })
            },
            handleSubmit(name) {
                var Tself = this;
                if (this.loginVO1.email !== '' && this.loginVO1.password !== '') {
                    this.$axios({
                            method: 'post',
                            url: "/user/login",
                            data: this.loginVO1
                        }
                    ).then((response) => {
                        console.log(response)
                        //成功登陆
                        if (response.data.data.items.message === "password error") {
                            this.$Message.info(response.data.data.items.message + " !");
                        } else if (response.data.data.items.message === "email not exist") {
                            this.$Message.info(response.data.data.items.message + " !");
                        } else {
                            //this.$router.go(-1);
                            Tself.$cookies.set('user_id', response.data.data.items.userId);
                            Tself.$store.commit('setLogin', response.data.data.items.userId)
                            setUserId(response.data.data.items.userId)
                            this.$axios.post(
                                "/user/findById",
                                response.data.data.items.userId,
                                {
                                    headers: {
                                        'Content-Type': 'text/xml'
                                    }
                                }
                            ).then((response2) => {
                                let uf = response2.data.data.items;
                                Tself.$cookies.set('user_name', uf.username);
                                Tself.$cookies.set('user_picture', uf.faceUrl);
                                Tself.$store.commit('setUserInfo', {
                                    userName: uf.username,
                                    userPic: uf.faceUrl
                                })
                                Tself.$router.push(Tself.$route.query.redirect || '/');
                                // Tself.$router.go(0)
                                Tself.$Message.success("Success!");
                            }).catch((error) => {
                                console.log(error);
                                this.$Message.error(error.message);
                            })
                        }
                    }).catch((error) => {
                        console.log(error)
                        this.$Message.error(error.message);
                    })
                } else {
                    this.$Message.error('Wrong');
                }


            },

            signUp_log() {
                this.$router.push("/register");
            }
        },
        mounted() {
            this.getCaptcha()
        },
        created() {

        }
    }
</script>


<style>
    .signIn_logo {
        height: 15%;
        width: 100%;
    }
</style>
